import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSpotBalances,
  updateAutoDual,
  fetchAutoDual,
} from "_store/duals.slice";
import CustomInput from "./CustomInput";

const DualSwitch = ({ dark, dualInfo, autoFlag, dualInfoRef, sumInvestMoney = 0 }) => {

  const dispatch = useDispatch();

  const handleCustomInputVal = (val, key) => {
    switch (key) {
      case 1:
        dualInfoRef.current = { ...dualInfoRef.current, threshold1: val }
        break;
      case 2:
        dualInfoRef.current = { ...dualInfoRef.current, threshold2: val }
        break;
      case 3:
        dualInfoRef.current = { ...dualInfoRef.current, threshold3: val }
        break;
      case 4:
        dualInfoRef.current = { ...dualInfoRef.current, threshold4: val }
        break;
      case 5:
        dualInfoRef.current = { ...dualInfoRef.current, closerStrike1: val }
        break;
      case 6:
        dualInfoRef.current = { ...dualInfoRef.current, closerStrike2: val }
        break;
      default:
        break;
    }
    dispatch(updateAutoDual(dualInfoRef.current))
  }

  useEffect(() => {
    if (dualInfo) {
      dualInfoRef.current = {
        threshold1: dualInfo.threshold1 || 0,
        threshold2: dualInfo.threshold2 || 0,
        threshold3: dualInfo.threshold3 || 0,
        closerStrike1: dualInfo?.closer_strike1 || 0,
        closerStrike2: dualInfo?.closer_strike2 || 0,
        increaseSellOrder: dualInfo.increase_sell_order || 0
      }
    }
    // setIsSwitchCheck(dualInfoRef.current.multiplayer);
  }, [dualInfo])

  return (
    <div className="flex flex-col w-[420px]">
      <div className="relative">
        <h1 className="font-syn-bold text-3xl relative">
          <span className="shadow-text">DUAL-INVESTMENT</span>

        </h1>
        <div className="flex mt-3">
          <div className="dark:bg-[#4c515938] bg-[#D7AD7D31] py-2 rounded-b-lg border-t-4 border-[#1D8EFF]">
            <div className="flex items-center px-4 justify-between">
              <span>Term</span>
              <span>{'APR >'}</span>
            </div>
            <hr className="h-[1px] my-1 dark:border-[#1c1c1c] border-white"/>
            <div className="px-4">
              <div className="flex items-center justify-between">
                {`1`}&nbsp;&nbsp;
                <span className="flex items-center pl-3">
                  <CustomInput
                    dark={dark}
                    color={"#ddd"}
                    styleObj={{ width: "50px", textAlign: 'right !important' }}
                    type={1}
                    disabled={!!autoFlag || false}
                    val={dualInfo.threshold1 || 0} // Fallback to 0 if undefined
                    handleCustomInputVal={handleCustomInputVal}
                    index={1}
                  />
                  %
                </span>
              </div>
              <div className="flex items-center justify-between">
                {`2`}&nbsp;&nbsp;
                <span className="flex items-center pl-3">
                  <CustomInput
                    dark={dark}
                    color={"#ddd"}
                    styleObj={{ width: "50px", textAlign: 'right !important' }}
                    type={1}
                    disabled={!!autoFlag || false}
                    val={dualInfo.threshold2 || 0}
                    handleCustomInputVal={handleCustomInputVal}
                    index={2}
                  />%
                </span>
              </div>
              <div className="flex items-center justify-between">
                {`7 - 14`}&nbsp;&nbsp;
                <span className="flex items-center pl-3">
                  <CustomInput
                    dark={dark}
                    color={"#ddd"}
                    styleObj={{ width: "50px", textAlign: 'right !important' }}
                    type={1}
                    disabled={!!autoFlag || false}
                    val={dualInfo.threshold3 || 0}
                    handleCustomInputVal={handleCustomInputVal}
                    index={3}
                  />
                  %
                </span>
              </div>
            </div>
          </div>
          <div className="ml-[2rem]">
            <div className="flex items-center justify-between">
              Investment:&nbsp;
              <span className="flex items-center">
                <CustomInput
                  dark={dark}
                  // color={balance > sumInvestMoney ? "#ddd" : "#ff1d1d"}
                  styleObj={{ width: "50px", textAlign: 'right !important' }}
                  type={1}
                  disabled={!!autoFlag || false}
                  val={parseInt(sumInvestMoney)} // Assuming this is a controlled input, set a default value
                />{" "}
                $
              </span>
            </div>
            <div className="flex items-center justify-between">
              {`BTC Closer strike <`}&nbsp;&nbsp;
              <span className="flex items-center">
                <CustomInput
                  dark={dark}
                  color={"#ddd"}
                  styleObj={{ width: "50px", textAlign: 'right !important' }}
                  type={1}
                  disabled={!!autoFlag || false}
                  val={dualInfo?.closer_strike1 || 0}
                  handleCustomInputVal={handleCustomInputVal}
                  index={5}
                />$
              </span>
            </div>
            <div className="flex items-center justify-between">
              {`ETH Closer strike <`}&nbsp;&nbsp;
              <span className="flex items-center">
                <CustomInput
                  dark={dark}
                  color={"#ddd"}
                  styleObj={{ width: "50px", textAlign: 'right !important' }}
                  type={1}
                  disabled={!!autoFlag || false}
                  val={dualInfo?.closer_strike2 || 0}
                  handleCustomInputVal={handleCustomInputVal}
                  index={6}
                />$
              </span>
            </div>
            {/* <div className="flex items-center justify-between">
              {`Increase > Strike`}&nbsp;&nbsp;<span>+{}
                <CustomInput
                  dark={dark}
                  color={"#ddd"}
                  styleObj={{ width: "50px", textAlign: 'right !important' }}
                  type={1}
                  disabled={!!autoFlag || false}
                  val={dualInfo.increase_sell_order || 0}
                  handleCustomInputVal={handleCustomInputVal}
                  index={6}
                />x</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DualSwitch;
