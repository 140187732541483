import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "_helpers";
import Swal from "sweetalert2";

const initialState = {
  dualInvestments: {},
  dualInvestObj: {},
  dualList: {},
  limitSellList: {},
  currentPrice: [0, 0],
  buyLowAmount: 100,
  sellHighAmount: 0.00156,
  status: "idle",
  error: null,
  isChecked: false,
  selectedCrypto: "BTC",
  balances: [0.00, 0.00],
  dualInfo: {
    threshold1: 0,
    threshold2: 0,
    threshold3: 0,
    threshold4: 0,
    closerStrike1: 0,
    closerStrike2: 0,
    increaseSellOrder: 0,
  }
};

const baseUrl = `${fetchWrapper.api_url}/api`;

// get info for auto dual by userid
export const fetchAutoDual = createAsyncThunk(
  "duals/fetchAutoDual",
  async (data) => {
    const response = await fetchWrapper.post(baseUrl + "/fetch-auto-dual", data);
    return response;
  }
);

// get dualplan
export const fetchInvestmentsByCurrency = createAsyncThunk(
  "duals/fetchInvestmentsByCurrency",
  async (currency) => {
    const response = await fetchWrapper.get(
      baseUrl + `/fetch-investments?currency=${currency}`
    );
    return response;
  }
);

// get price of coin
export const fetchSpotPrice = createAsyncThunk(
  "duals/fetchSpotPrice",
  async (currencyPair) => {
    const response = await fetchWrapper.get(
      baseUrl + `/get-spot-price?currencyPair=${currencyPair}`
    );
    return response.spotPrice;
  }
);

// get balance of spot account 
export const fetchSpotBalances = createAsyncThunk(
  "duals/fetchSpotBalances",
  async ({ subClientId, cryptoCurrency }) => {
    const response = await fetchWrapper.get(
      baseUrl + `/fetch-spot-balances?subClientId=${subClientId}&cryptoCurrency=${cryptoCurrency}`
    );
    return response;
  }
);

// get opened dual list
export const fetchOpenDuals = createAsyncThunk(
  "duals/fetchOpenDuals",
  async ({ subClientId, cryptoCurrency }) => {
    const response = await fetchWrapper.get(
      baseUrl + `/fetch-opened-duals?userId=${subClientId}&currency=${cryptoCurrency}`
    );
    return response;
  }
);

// set switch on/off 
export const toggleAutoDual = createAsyncThunk(
  "duals/toggleAutoDual",
  async (_, { getState }) => {
    const state = getState().duals;
    const authUser = getState().auth.user;
    const subClientId = authUser[1].id;

    const payload = {
      active: !state.isChecked,
      currency: state.selectedCrypto,
      amount: state.buyLowAmount,
      // dualInvestments: state.dualInvestments,
      dualType: "buyLow",
      subClientId: subClientId,
      dualInvestObj: state.dualInvestObj
    };

    const response = await fetchWrapper.post(baseUrl + "/auto-dual", payload);
    return response;
  }
);

// get balanc
export const fetchBalances = createAsyncThunk(
  "duals/fetchBalances",
  async (subClientId) => {
    const response = await fetchWrapper.post(
      baseUrl + "/get-balances", { subClientId }
    );
    return response;
  }
);

export const toggleChecked = () => async (dispatch, getState) => {
  const state = getState().duals;
  try {
    const result = await dispatch(toggleAutoDual());
    if (result.meta.requestStatus === "fulfilled") {
      dispatch(dualsSlice.actions.toggleCheckedState(state.isChecked));
    }
  } catch (error) {
    console.error("Error toggling auto dual:", error);
  }
};

export const updateAutoDual = createAsyncThunk(
  "duals/updateAutoDual",
  async (data, { getState }) => {
    const state = getState().duals; // Get the current state
    const { selectedCrypto } = state;
    const authUser = getState().auth.user;
    console.log("updateAutoDual");

    const response = await fetchWrapper.post(baseUrl + "/update-auto-dual", {
      ...data,
      subClientId: authUser[1].id,
      currency: selectedCrypto,
    });
    return response;
  }
);

const dualsSlice = createSlice({
  name: "duals",
  initialState,
  reducers: {
    toggleCheckedState: (state, action) => {
      state.isChecked = !action.payload;
    },
    updateSelectedCrypto: (state, action) => {
      state.selectedCrypto = action.payload;
    },
    updateDualInvestments: (state, action) => {
      const { key, value } = action.payload;
      state.dualInvestObj[key] = value;
    },
    updateBalances: (state, action) => {
      state.balances = action.payload; // Add balances to state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvestmentsByCurrency.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInvestmentsByCurrency.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dualInvestments = action.payload;
        const btcPrice = action.payload?.btc?.current || 0
        const ethPrice = action.payload?.eth?.current || 0
        state.currentPrice = [btcPrice, ethPrice];
      })
      .addCase(fetchInvestmentsByCurrency.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAutoDual.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAutoDual.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dualInfo = action.payload;
        state.isChecked = !!action.payload.active;
        if (!!action.payload.dualInvest_obj) state.dualInvestObj = {...JSON.parse(action.payload.dualInvest_obj)}
        else state.dualInvestObj = {}
      })
      .addCase(fetchAutoDual.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSpotBalances.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSpotBalances.fulfilled, (state, action) => {
        state.status = "succeeded";
        const [usdtBalance, cryptoBalance] = action.payload;
        state.usdtBalance = usdtBalance;
        state.cryptoBalance = cryptoBalance;
      })
      .addCase(fetchSpotBalances.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchOpenDuals.fulfilled, (state, action) => {
        state.dualList = [...action.payload.buy];
        state.limitSellList = [...action.payload.sell];
      })
      .addCase(toggleAutoDual.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleAutoDual.fulfilled, (state, action) => {
        state.status = "succeeded";
        const message = action.payload.active
          ? "Auto dual activated successfully!"
          : "Auto dual deactivated successfully!";
        Swal.fire({
          icon: "success",
          title: "Success",
          text: message,
        });
      })
      .addCase(toggleAutoDual.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Failed to toggle auto dual: ${action.error.message}`,
        });
      })
      .addCase(fetchBalances.fulfilled, (state, action) => {
        state.balances = action.payload; // Store fetched balances
      })
      .addCase(updateAutoDual.fulfilled, (state, action) => {
        // Handle success if needed
      })
      .addCase(updateAutoDual.rejected, (state, action) => {
        // Handle error if needed
      });
  },
});

// Export actions and reducer
export const {
  updateAprToBuy,
  toggleCheckedState,
  updateSelectedCrypto,
  updateDualInvestments,
  updateAprThreshold,
  updateCloserStrike,
  updateScaleBy,
} = dualsSlice.actions;
export const dualsReducer = dualsSlice.reducer;
