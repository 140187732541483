import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import DualSwitch from "_components/DualInvestSidebar/DualSwitch";
import CryptoList from "./CryptoList";
import {
  fetchSpotBalances,
  updateAutoDual,
  fetchAutoDual,
} from "_store/duals.slice";
import PriceRangeTable from "./PriceRangeTable";

const arrCoinUnit = {
  'BTC': 500,
  'ETH': 25
};

const DualInvestSidebar = ({ show, dark }) => {
  const [sumInvestMoney, setSumInvestMoney] = useState(0);
  const [isSwitchCheck, setIsSwitchCheck] = useState(false);
  const [multi, setMulti] = useState(0);
  const dualInfoRef = useRef({});

  const { user: authUser } = useSelector((x) => x.auth);
  const { selectedCrypto, balances, dualInfo, isChecked: autoFlag, dualInvestObj } = useSelector((state) => state.duals);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedCrypto) {
      setMulti(arrCoinUnit[selectedCrypto] || 0);
      dispatch(fetchSpotBalances({
        subClientId: authUser[1].id,
        cryptoCurrency: selectedCrypto,
      }));
      dispatch(fetchAutoDual({
        subClientId: authUser[1].id,
        currency: selectedCrypto
      }));
    }
  }, [selectedCrypto, authUser, dispatch]);

  useEffect(() => {
    if (dualInvestObj) {
      let sum = 0;
      Object.keys(dualInvestObj).forEach((item) => {
        if (!isNaN(parseInt(dualInvestObj[item]))) {
          sum += parseInt(dualInvestObj[item]);
        }
      });
      if (sumInvestMoney !== sum) setSumInvestMoney(sum);
    }
  }, [dualInvestObj, sumInvestMoney]);

  const balance = balances && balances[0] !== undefined && !isNaN(parseFloat(balances[0])) ? parseFloat(balances[0]).toFixed(2) : 0;

  return (
    <div className="text-investment relative max-w-[1024px] rounded-[25px]">
      <div
        style={{
          height: "570px",
          width: "100%",
          minWidth: "650px",
          maxWidth: "1024px",
          marginTop: "-25px"
        }}
        className={`${show ? "right-0" : "hidden"} text-xs font-inter text-white`}
      >
        <div className="flex justify-between">
          <DualSwitch dark={dark} dualInfo={dualInfo} autoFlag={autoFlag} dualInfoRef={dualInfoRef} />
        </div>
        <div className="flex flex-row w-full justify-end">
          <PriceRangeTable dualInfoRef={dualInfoRef} />
        </div>
      </div>
    </div>
  );
};

DualInvestSidebar.propTypes = {
  show: PropTypes.bool.isRequired,
  dark: PropTypes.bool.isRequired,
};

export default DualInvestSidebar;
