import React, { useState, useRef, useEffect } from "react";

const CustomInput = ({ dark, color, type, val, index, handleCustomInputVal, disabled=false }) => {
  const [currentVal, setCurrentVal] = useState(0)
  const [isFocused, setIsFocused] = useState(false);

  const handleValFunc = (e) => {
    let val = e.target.value !== '' ? parseInt(e.target.value) : ''
    setCurrentVal(val)
    if (!!handleCustomInputVal) {
      handleCustomInputVal(val, index)
    }
  }

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  useEffect(() => {
    if (!!val) setCurrentVal(val)
    else setCurrentVal(0)
  }, [val])

  return (
    <span>
      <input
        type="number"
        value={currentVal == 0 && type == 0 ? '' : currentVal}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleValFunc}
        disabled={disabled ? 'disabled' : ''}
        style={{
          width: '48px',
          borderColor: isFocused ? color : (type === 1 ? 'transparent' : color)
        }}
        className={` border-[1px] rounded-sm bg-transparent ${type === 1 ? 'text-right' : 'text-center'} outline-none p-0 px-1 m-0 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
      />
    </span>
  );
};

export default CustomInput;
