import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend, Title } from 'chart.js';
import { fetchWrapper } from '_helpers/fetch-wrapper';

const baseUrl = `${fetchWrapper.api_url}/api`;

// Register Chart.js components
Chart.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend, Title);

const FundingRateChart = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Fetch funding rate data using your API endpoint
    const fetchFundingRates = async () => {
      try {
        const response = await fetchWrapper.get(baseUrl + `/funding-rates`);
        console.log(response, 'response');

        // Transform data into chart-compatible format
        let labels = [];
        let values = [];
        for (let i = response.length; i > 0; i--) {
          labels.push(new Date(response[i - 1].t * 1000).toLocaleString());
          values.push(response[i - 1].r * 100)
        }

        setChartData({
          labels,
          datasets: [
            {
              label: 'Funding Rate History',
              data: values,
              borderColor: '#FF6384',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              tension: 0.4,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching funding rate data:', error);
      }
    };
    fetchFundingRates();
  }, []);

  if (!chartData) return <div>Loading...</div>;

  return <Line data={chartData} />;
};

export default FundingRateChart;