import React from "react";

const SwitchBtn = ({ isChecked,  handleCheckboxChange}) => {

  return (
    <label className="themeSwitcherTwo relative inline-flex cursor-pointer select-none items-center">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        className="sr-only"
        style={{ width: "26px", height: "13.42px" }}
      />
      <span className={`slider flex h-[13.42px] w-[26px] p-[1px] items-center rounded-full duration-200 ${isChecked ? "bg-[#1D886A]" : "bg-[#50555F]" }`}>
        <span className={`dot h-[11.42px] w-[11.42px] rounded-full bg-white duration-200 ${isChecked ? "translate-x-[12px]" : ""}`}></span>
      </span>
    </label>
  );
};

export default SwitchBtn;
