import React from "react";
import lightLogo from "_assets/logo light mode.png";
import darkLogo from "_assets/logo_dark_mode.png";
import styles from "./Header.module.css";
import icon_sun from "_assets/icon_sun.svg";
import icon_moon from "_assets/icon_moon.svg";
import { NavLink } from "react-router-dom";

const NavbarLink = ({ to, label, className = "" }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `flex items-center mr-4 text-xxs cursor-pointer ${isActive ? "!text-[#30B58B]" : "!text-[#A3A2A2]"
      } ${className}`
    }
  >
    {label}
  </NavLink>
);

export function Navbar(
  {
    isDarkMode,
    toggleDarkMode,
    isAuthRoute,
    dark,
    authUser
  }) {
  if (isAuthRoute) return null;

  const authUserInfo = authUser ? authUser[1] : {};
  const headerTextClass = `${styles.headerText} ${isDarkMode ? styles.darkModeText : styles.lightModeText}`;
  const isClient = authUserInfo.user_roles === "client";
  const isSuperAdmin = authUserInfo.user_roles === "super_admin";

  const renderAdminLinks = () => (
    <>
      {isSuperAdmin && (
        <>
          <NavLink
            to="/admin"
            className="lg:flex space-x-2 mr-4 text-xxs text-[#A3A2A2] text-center cursor-pointer items-center"
          >
            Admin
          </NavLink>
          <NavbarLink to="/clients" label="Sub-Clients" />
        </>
      )}
      {isClient && (
        <NavbarLink to="/profile" label="Profile" />
      )}
      <NavbarLink to="/" label="DUAL-INVEST" />
      <NavbarLink to="/bot" label="Bot" />
    </>
  );

  return (
    <header className={styles.header}>
      <img className={styles.logo} src={isDarkMode ? darkLogo : lightLogo} alt="logo" />
      <div className={`${styles.headerNav}`}>
        <div className="flex pt-4 justify-between">
          <div className="flex justify-between w-full items-center text-[11px]">
            <div className="flex space-x-4 cursor-pointer"></div>
            <div className="flex place-items-baseline justify-between space-x-4">
              <div className="p flex justify-center">
                {renderAdminLinks()}
                <NavbarLink to="/exchange" label="Exchange" className="lg:flex" />
                <NavbarLink to="/faq" label="FAQ" className="lg:flex" />
                <span className={'lg:flex space-x-2 mr-4 text-xxs text-[#A3A2A2] text-center cursor-pointer items-center'} onClick={toggleDarkMode}>
                  <span className={''}>MODE</span>
                  <img src={isDarkMode ? icon_sun : icon_moon} alt="mode-toggle" className={'w-[15px] h-[15px]'} />
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <Link to="/whitepaper" className={styles.headerLink}>
          <span className={headerTextClass}>WHITEPAPER</span>
        </Link>*/}
      </div>
    </header>
  );
}