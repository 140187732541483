const styles = {
    dark: {
      // boxShadow: "1px 4px 4px #151515 inset",
      // border: "1px #2B3036 solid",
    },
    light: {
      // background: "#FEF6E6",
      // border: "1px #C9C5C5 solid",
    },
  };
  
  export default styles;
  