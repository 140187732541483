import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "./CustomInput"
import {
    fetchSpotPrice,
    updateDualInvestments,
    fetchOpenDuals,
    toggleChecked,
    fetchInvestmentsByCurrency
} from "_store/duals.slice";
import TouchEveContainer from './CustomTable/TouchEveContainer'
// import { fetchWrapper } from "_helpers";
import expandToggle from "_assets/expand.svg";
import SwitchBtn from '_components/common/SwitchBtn';
import TableCell from './CustomTable/TableCell';
import TableHeader from './CustomTable/TableHeader';
import CheckRow from './CustomTable/CheckRow'

const DualPlanRow = ({ item, dark, dualInvestObj, handleShareChange, autoFlag }) => (
    <tr>
        <TableCell className='!py-0 !px-0' divClassName='my-[3px] mx-1 w-[48px] text-center'>
            <CustomInput
                dark={dark}
                type={1}
                color={'#525458'}
                val={dualInvestObj[item.exercisePrice]}
                disabled={!!autoFlag || false}
                index={item.exercisePrice}
                handleCustomInputVal={handleShareChange}
            />
        </TableCell>
        <TableCell className='dark:bg-[#272b30] bg-[#faf2e2] !px-0.5'></TableCell>
        <TableCell>{item.term} Day</TableCell>
        <TableCell>{item.exerciseCurrency}</TableCell>
        <TableCell>{item.exercisePrice}</TableCell>
        <TableCell divClassName='!text-[#17E5A3]'>{(item.apyDisplay).toFixed(0)}</TableCell>
        <TableCell></TableCell>
    </tr>
);

const ExpandToggle = ({ setIsExpand, isExpand, index }) => (
    <div className="flex flex-row items-center ml-3">
        <span className='cursor-pointer' onClick={() => setIsExpand(isExpand === 0 ? index : 0)}>
            <img src={expandToggle} width={14} alt="expand-toggle" /> {/*expandToggle needs to be defined*/}
        </span>
    </div>
);

const PriceRangeTable = ({ dark, dualInfoRef }) => {

    const [isChecked, setIsChecked] = useState(false)
    const [isExpand, setIsExpand] = useState(0)

    // const selCellsRef = useRef({})

    const dispatch = useDispatch();
    const { selectedCrypto, limitSellList, dualInvestments, dualInvestObj, currentPrice, isChecked: autoFlag, dualList } = useSelector((state) => state.duals);
    const { user: authUser } = useSelector((x) => x.auth);

    // merge sell orders with canceling opened sell_order
    // const setMergeItems = async () => {
    //     const selIds = [];
    //     let len = 0;
    //     Object.keys(selCellsRef.current).forEach((item) => {
    //         if (selCellsRef.current[item] === true) {
    //             len++;
    //             selIds.push(item);
    //         }
    //     })
    //     console.log(selCellsRef.current);
    //     if (len < 2 || autoFlag) {
    //         setIsChecked(false)
    //         len < 2 ? alert('Please select 2 or more items') : alert('Auto-Dual working!')
    //     } else {
    //         const response = await fetchWrapper.post(fetchWrapper.api_url + "/api/merge-sell-orders", { selIds, currency: selectedCrypto, userId: authUser[1].id });
    //         if (response.msg === 'success') {
    //             selCellsRef.current = {};
    //             setIsChecked(false)
    //             getDualData(0);
    //         }
    //         return;
    //     }
    // }


    // merge sell order
    const handleMergeCheckboxChange = () => {
        setIsChecked(!isChecked)
        // if (!isChecked) setMergeItems();
    };

    // turn on/off auto dual method
    const handleAutoCheckboxChange = () => {
        dispatch(toggleChecked());
    };

    // get coin price and dualplan list
    const getDualData = (count) => {
        if (count % 3 === 0)
            dispatch(fetchOpenDuals({
                subClientId: authUser[1].id,
                cryptoCurrency: 'all',
            }));
        dispatch(fetchInvestmentsByCurrency('all'))
    }

    useEffect(() => {
        // Update data when selectedCrypto changes, without setting up the interval repeatedly
        if (selectedCrypto) {
            getDualData(0);
        }
    }, [selectedCrypto, authUser]);

    // useEffect(() => {
    //     if (selectedCrypto && arrCoinUnit[selectedCrypto] && currentPrice && currentPrice > 0) {
    //         const multi = Math.round(currentPrice / arrCoinUnit[selectedCrypto])
    //         setRangeStrike((multi + 15) * arrCoinUnit[selectedCrypto])
    //     }
    // }, [currentPrice]);

    useEffect(() => {
        let intervalId;
        let count = 0;
        clearInterval(intervalId)
        // Set interval on mount and clear it on unmount
        if (selectedCrypto) {
            intervalId = setInterval(() => {
                getDualData(count);
                count++;
            }, 10000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [selectedCrypto]);

    // save invest money by {key}
    const handleShareChange = (value, key) => {
        dispatch(updateDualInvestments({ value, key }));
    };

    const isRightCond = (term, apr) => {
        return  (term > 6 && apr >= dualInfoRef.current.threshold3) || (term > 1 && apr >= dualInfoRef.current.threshold2) || (term === 1 && apr >= dualInfoRef.current.threshold1);
    }

    return (
        <div className='block w-full'>
            <div className='flex justify-between'>
                <div className={`mr-5 ${isExpand === 3 ? 'dark:bg-[#252a30] bg-[#fff4e4] expand-table' : 'mt-5'}`}>
                    <div className="pl-[65px] pb-1">
                        <div className='flex justify-between'>
                            <span className='text-[15px] dark:!text-[#D5BEAB] !text-[#D7AD7D] font-[syncopate-bold]'>DUALINVESTMENT-TRACKER</span>

                        </div>
                    </div>
                    <TouchEveContainer
                        bColor="#1D8EFF"
                        wid={isExpand === 3 ? '100%' : ''}
                        heg={isExpand === 3 ? '95%' : '160px'}>
                        <table className="select-none min-w-full border-separate border-spacing-0">
                            <thead>
                                <tr className=''>
                                    <TableHeader className='border-t-[#1D8EFF] w-[50px]' divClassName='w-[48px] text-end'>Invest</TableHeader>
                                    <TableHeader className='dark:bg-[#272b30] bg-[#faf2e2] !px-0.5 !border-t-0'></TableHeader>
                                    <TableHeader className={'border-t-[#1D8EFF]'}>Term</TableHeader>
                                    <TableHeader className={'border-t-[#1D8EFF]'}>Symbol</TableHeader>
                                    <TableHeader className={'border-t-[#1D8EFF]'}>Settlement</TableHeader>
                                    <TableHeader className={'border-t-[#1D8EFF]'}>APR</TableHeader>
                                    <TableHeader className={'border-t-[#1D8EFF]'}>
                                        <ExpandToggle setIsExpand={setIsExpand} isExpand={isExpand} index={3} />
                                    </TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dualInvestments?.btc?.investments.map((item, rowKey) => {
                                        if (isRightCond(item.term, item.apyDisplay))
                                            return <DualPlanRow
                                                key={rowKey}
                                                item={item}
                                                dark={dark}
                                                dualInvestObj={dualInvestObj}
                                                handleShareChange={handleShareChange}
                                                autoFlag={autoFlag}
                                            />
                                    })
                                }
                                {
                                    dualInvestments?.eth?.investments.map((item, rowKey) => {
                                        if (isRightCond(item.term, item.apyDisplay))
                                            return <DualPlanRow
                                                key={rowKey}
                                                item={item}
                                                dark={dark}
                                                dualInvestObj={dualInvestObj}
                                                handleShareChange={handleShareChange}
                                                autoFlag={autoFlag}
                                            />
                                    })
                                }
                            </tbody>
                        </table>
                    </TouchEveContainer>
                </div>
                <div className={`${isExpand === 1 ? 'dark:bg-[#252a30] bg-[#fff4e4] expand-table' : 'mt-5'}`}>
                    <div className="pb-1">
                        <div className='flex justify-between'>
                            <span className='text-[15px] dark:!text-[#D5BEAB] !text-[#D7AD7D] font-[syncopate-bold]'>DUAL-HOLDING &nbsp;
                                <SwitchBtn isChecked={autoFlag} handleCheckboxChange={handleAutoCheckboxChange} />
                            </span>
                            <div className="flex flex-row items-center">
                                <span className='text-[13px]'>Price: &nbsp;{currentPrice[0].toFixed(0)} $(BTC) &nbsp;{currentPrice[1].toFixed(0)} $(ETH)</span>
                            </div>
                        </div>
                    </div>
                    <TouchEveContainer
                        bColor="#17E5A3"
                        wid={isExpand === 1 ? '100%' : ''}
                        heg={isExpand === 1 ? '95%' : '160px'}>
                        <table className="select-none min-w-full border-separate border-spacing-0">
                            <thead>
                                <tr className=''>
                                    <TableHeader className={'border-t-[#17E5A3]'}>Term</TableHeader>
                                    <TableHeader className={'border-t-[#17E5A3]'}>Symbol</TableHeader>
                                    <TableHeader className={'border-t-[#17E5A3]'}>Target Price</TableHeader>
                                    <TableHeader className={'border-t-[#17E5A3]'}>APR</TableHeader>
                                    <TableHeader className={'border-t-[#17E5A3]'}>Amount</TableHeader>
                                    <TableHeader className={'border-t-[#17E5A3]'}>Maturity Date</TableHeader>
                                    <TableHeader className={'border-t-[#17E5A3]'}>Status</TableHeader>
                                    <TableHeader className={'border-t-[#17E5A3]'}>
                                        <ExpandToggle setIsExpand={setIsExpand} isExpand={isExpand} index={1} />
                                    </TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dualList.length && dualList.map((dual, rowKey) => {
                                        const arrDate = (dual.settlementTime).split('T')
                                        return <tr key={rowKey}>
                                            <TableCell>{dual.term} Day</TableCell>
                                            <TableCell>{dual.currency}</TableCell>
                                            <TableCell>{dual.strikePrice}</TableCell>
                                            <TableCell>{parseInt(dual.apy)}</TableCell>
                                            <TableCell>{dual.investAmount}</TableCell>
                                            <TableCell>{arrDate[0]}</TableCell>
                                            <TableCell divClassName='!text-[#00AEFF]'>{'Holding'}</TableCell>
                                            <TableCell></TableCell>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </TouchEveContainer>
                </div>
            </div>
            <div className={`w-100 ${isExpand === 2 ? 'dark:bg-[#252a30] bg-[#fff4e4] expand-table' : 'mt-5 mb-5'} `}>
                <div className="">
                    <div className='flex justify-between  pb-1 '>
                        <span className='text-[15px] dark:!text-[#D5BEAB] !text-[#D7AD7D] font-[syncopate-bold]'>INVESTMENT - RECOUPED</span>
                        <div className="flex flex-row items-center pr-14">
                            <span className={`mr-4 dark:!text-[#D5BEAB] !text-[#D7AD7D] font-[syncopate-bold]`} >
                                Merget
                            </span>
                            <SwitchBtn isChecked={isChecked} handleCheckboxChange={handleMergeCheckboxChange} />
                        </div>
                    </div>
                </div>
                <TouchEveContainer
                    bColor="#EA5F00"
                    wid={isExpand === 2 ? '100%' : '100%'}
                    heg={isExpand === 2 ? '95%' : '160px'}>
                    <table className="select-none min-w-full border-separate border-spacing-0">
                        <thead>
                            <tr className=''>
                                <TableHeader className='border-t-[#EA5F00]'>check</TableHeader>
                                <TableHeader className='border-t-[#EA5F00]'>Term</TableHeader>
                                <TableHeader className='border-t-[#EA5F00]'>Symbol</TableHeader>
                                <TableHeader className='border-t-[#EA5F00]'>Target Price</TableHeader>
                                {/* <TableHeader className='border-t-[#EA5F00]'>APR</TableHeader> */}
                                <TableHeader className='border-t-[#EA5F00]'>Amount</TableHeader>
                                <TableHeader className='border-t-[#EA5F00]'>Time Subscribed</TableHeader>
                                <TableHeader className='border-t-[#EA5F00]'>Maturity Date</TableHeader>
                                <TableHeader className='border-t-[#EA5F00]'>Status</TableHeader>
                                <TableHeader className='border-t-[#EA5F00]'>Coin/USDT</TableHeader>
                                <TableHeader className='border-t-[#EA5F00]'>Sell Order</TableHeader>
                                <TableHeader className='dark:bg-[#272b30] bg-[#faf2e2] !px-0.5 !border-t-0'></TableHeader>
                                <TableHeader className='border-t-[#FF002A]'>Amount</TableHeader>
                                <TableHeader className='border-t-[#FF002A]'>Merget</TableHeader>
                                <TableHeader className='border-t-[#FF002A]'>
                                    <ExpandToggle setIsExpand={setIsExpand} isExpand={isExpand} index={2} />
                                </TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                limitSellList.length > 0 && limitSellList.map((item, rowKey) => {
                                    const subscribeDate = (item.createdAt).split('T');
                                    const maturityDate = (item.updatedAt).split('T');
                                    return <tr key={rowKey}>
                                        <TableCell>
                                            <CheckRow value='1' stateRef={''}/>
                                        </TableCell>
                                        <TableCell>{item.term} Day</TableCell>
                                        <TableCell>{item.currency}</TableCell>
                                        <TableCell>{item.baseStrikePrice}</TableCell>
                                        <TableCell>{item.investAmount}</TableCell>
                                        <TableCell>{subscribeDate[0]}</TableCell>
                                        <TableCell>{maturityDate[0]}</TableCell>
                                        <TableCell divClassName='!text-[#00AEFF]'>{item.state}</TableCell>
                                        <TableCell>{item.amount}</TableCell>
                                        <TableCell divClassName='!text-[#EA5F00]'>{item.baseStrikePrice}</TableCell>
                                        <TableCell className='dark:bg-[#272b30] bg-[#faf2e2] !px-0.5'></TableCell>
                                        <TableCell className='' divClassName='!text-[#FF002A]'>{item.baseStrikePrice}</TableCell>
                                        <TableCell divClassName='!text-[#EA5F00]'>{item.baseStrikePrice}</TableCell>
                                        <TableCell></TableCell>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </TouchEveContainer>
            </div>
        </div>
    );
};

export default PriceRangeTable;
