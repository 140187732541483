import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { FaEdit, FaSave } from "react-icons/fa";
import Swal from 'sweetalert2';
import { userActions, authActions } from '_store';
import { fetchWrapper } from '_helpers';

function ProfileSideBar({
  show,
  dark,
  loggedInUser,
  width,
}) {

  const [editFlag, setEditFlag] = useState(0);
  const [userInfo, setUserInfo] = useState({})
  const dispatch = useDispatch();

  const customDarkStyles = {
    // border: '1px #484746 solid'
  };
  const customLightStyles = {
    // border: "1px #C9C5C5 solid"
  };

  const data = userInfo
  let userData = {
    username: data.username,
    email: data.email,
    password: data.password,
    api_key: data.api_key,
    api_secret: data.api_secret,
    personal_id: data.personal_id ?? '',
    address: data.address ?? ''
  };


  const hadleEdit = () => {
    setEditFlag(1);
  }

  const editUser = (id, userData) => {
    let data = userData;
    
    data.id = id;
    dispatch(userActions.updateUser(data));
  }

  const handleSave = () => {
    setEditFlag(0);
    editUser(data.id, userData);
  }

  const onChangeData = (e, field) => {
    userData[field] = e.target.value;
  }

  const keys = Object.keys(userData);

  useEffect(() => {
    const baseUrl = `${fetchWrapper.api_url}/api`;
    fetchWrapper.get(baseUrl+'/user_info/' + loggedInUser.userId).then((result) => {
      setUserInfo(result)
    })
  }, [])

  return (
    <div
      style={dark ? customDarkStyles : customLightStyles}
      className={`!h-[530px] ${show ? "right-0" : "hidden"
        } md:w-[${width}px] h-full  px-5 py-3 z-30 transition-transform text-xs dark:bg-[transparent] dark:border-[#6D6D6D] rounded-[25px]`}
    >
      <div className="mb-8 mx-5">
        <div className="flex justify-between pb-5" >
          <h2 className="font-syn-bold text-3xl relative">
            <span className="shadow-text">customer profile</span>
          </h2>
          <span className="flex items-center text-lg text-[#444] gap-4 font-syncopate-light dark:text-dark-text">
            {editFlag ? <FaSave onClick={handleSave} /> : <FaEdit onClick={hadleEdit} />}
          </span>
        </div>
        {
          keys.map(
            (field, index) => {
              return (
                <div key={index} className="grid grid-flow-col">
                  <div className="w-full grid grid-cols-3  border-r-0 border-[#999] border-solid border-b lg:p-1 lg:px-5 p-1 pl-2 text-xs ">
                    <span
                      className={`font-syncopate-light text-[#9b9b9b] flex items-center`}>
                      {field}
                    </span>
                    <div className="col-span-2 flex">
                      <input
                        type={['wallet', 'investment', 'Net_client_share_in_percent', 'account_no', 'state'].includes(field) ? 'number' : (field === 'begin_date' ? 'date' : 'text')}
                        className={`font-syncopate-light text-base font-mono w-[100%] ${dark
                          ? 'text-[#9b9b9b] bg-[transparent] focus:bg-[#454545]'
                          : 'text-[#9b9b9b]'
                          }`}
                        onChange={(e) => onChangeData(e, field)}
                        disabled={!editFlag}
                        defaultValue={data[field]}
                        placeholder={(['personal_id', 'address'].includes(field)) ? 'Not required' : ''}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          )
        }
      </div>
    </div>
  );
}

export { ProfileSideBar };
